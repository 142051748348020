export default {
  shop_center_txt: 'ショップセンター',
  score_txt: 'ポイント',
  minute_txt: '分',
  wallet_txt: 'ウォレット',
  today_txt: '今日',
  week_txt: '今週',
  month_txt: '今月',
  year_txt: '一年中',
  main_indicators_txt: 'メインインジケーター',
  sale_price: 'セール価格',
  expected_profit: '期待利益',
  order_count: '注文数量',
  goods_click_count: '商品のクリック数',
  supplier_txt: 'サプライヤー',
  friends_help_txt: '友達が助けてくれます',
  feedback_txt: 'フィードバック',
  feedback: 'フィードバック',
  online_service_txt: 'オンライン サービス',
  q_and_a_txt: '質問と回答',
  open_now_txt: '開く',
  certification_activation_code: '認定アクティベーション コード',
  plz_write_invite_code: '招待コードを入力してください',
  time_txt: '時間',
  times_txt: '時間',

  wallet_total_assets: '総資産',
  balance_txt: '残高',
  pending_settlement_txt: '決済保留中',
  recharge_txt: 'リチャージ',
  withdraw_txt: '引き出し',
  fund_details_txt: 'ファンドの詳細',
  no_more_txt: 'これ以上はありません',
  no_data_txt: 'まだデータがありません',
  detail_txt: '詳細',
  num_txt: '数値',
  money_txt: '金額',
  remark_txt: '備考',
  no_remark_txt: 'コメントはありません',
  tip_txt: 'ヒント',
  tip_content_txt:
    '現在、通貨の問題により、さまざまな国の販売者が引き受けています',
  need_contact_service: 'カスタマーサービスに連絡する必要があります',
  contact_service: 'カスタマーサービスに連絡してください',

  feedback_title: '意見をフィードバックしてください',
  feedback_placeholder:
    'セキュリティ上の理由から、個人情報は含めないでください',
  submit_txt: '送信',
  sure_txt: 'OK',
  submit_suc_txt: '送信成功',

  loading: '読み込み中...',

  newer_action: '新人アクティビティ',
  newer_traffic_package: '新人サポート トラフィック パッケージ',
  newer_get_millions_of_products: '何百万もの製品を入手',
  newer_get: '受信しました',
  newer_receive: '受信',
  newer_action_ruler: '新人アクティビティ ルール',
  newer_receive_suc: '正常に受信しました',

  help_friends_help: '友達が助けてくれます',
  help_your_friends_stores: '友達のストアを手伝ってください',
  get_store_product_heating: '店舗製品の加熱を取得',
  help_friends_txt: '友達を助ける',
  help_friends_activity_rules: '友達を助けるアクティビティのルール',
  assistance_details: 'サポートの詳細',
  help_friend_name: '友達の名前を手伝ってください',
  plz_input: '入力してください',
  plz_input_friend_name: 'サポートしてくれる友人の名前を入力してください',
  help_suc: '成功を支援',

  online_service: 'オンラインカスタマーサービス',
  online_24_hour_service: '24 時間オンライン サポート',
  customer_service: '顧客サービス',
  copy_and_add_id: 'LINE IDをコピーして追加',
  plz_input_question_detail: '質問の詳細を入力してください',
  harry_reply_your: 'できるだけ早く返信させていただきます',
  click_copy: 'クリックしてコピー',
  copy_suc: 'コピー成功',
  copy_fail: 'コピーに失敗しました',

  overall: '全体的に',
  cooperate: '協力する',
  cancel: 'キャンセル',
  supplier_num: 'サプライヤー番号',
  no_use_supplier: '利用可能なサプライヤーがありません',
  here_add_supplier: 'ここに新しいサプライヤーを追加',
  add_supplier: 'サプライヤーを追加',
  plz_input_supplier_num: 'サプライヤー番号を入力してください',
  add_suc: '追加に成功しました',

  new_products_launched: '新製品が発売されました',
  profit_txt: '利益',
  has_been_added: 'すでに棚にあります',
  on_display: 'ディスプレイ上',
  coming_soon_txt: '現在入手可能です',
  sold_txt: '販売済み',
  removed_txt: '削除されました',

  order_detail_txt: '注文の詳細',
  waiting_express_delivery: '速達を待っています',
  customer_info: '顧客情報',
  order_good: '商品の注文',
  order_num: '注文番号',
  order_date: '注文日',
  order_content: '注文概要',
  purchase_price_txt: '購入価格',
  income_txt: '収入',

  order_txt: '注文',
  pending_payment: '支払いが保留中',
  ready_for_shipment: '出荷準備完了',
  shipped_txt: '発送済み',
  paid_txt: '配信済み',
  good_num: '商品番号',
  pay_and_ship: '支払いと発送',
  pay_suc: '支払いが成功しました',
  no_order: 'まだ注文はありません',
  no_order_tips: 'ここで注文と返品を追跡できます',
  suer_order: '支払いを確認',
  pay_way: '支払い方法',
  wallet_pay: 'ウォレット支払い',
  sure_pay: '支払いを確認',
  paying_txt: '支払い中...',

  manage_showcase: 'ショーケースを管理',
  add_new_good: '新しい製品を追加',
  heating_txt: '加熱',
  promotion: 'プロモーション',
  promotion_tip: 'プロモーションのヒント',
  promotion_tip_txt:
    '現在のポイントが 200 未満のため、商品の支払いができません',
  copy_link: 'リンクをコピー',
  scan_qr_code: 'QR コードをスキャン',

  about_good: '商品について',
  specification: '仕様',
  product_overview: '製品概要',

  top_tips_1: 'Tiktok ストアをオンラインに変換する',
  top_tips_2: '現在は内部招待のみをサポートしています',
  active_place_holeder: '入力してください',
}
