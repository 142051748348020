import { createI18n } from 'vue-i18n'

import zh from './zh'
import en from './en'
import hk from './hk'
import ja from './jp'
import ko from './kr'
import russian from './russian'
import vi from './van'

const i18n = createI18n({
  locale: sessionStorage.getItem('lang') || 'zh',
  messages: {
    zh,
    en,
    hk,
    ja,
    ko,
    russian,
    vi,
  },
})

export default i18n
