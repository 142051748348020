export default {
  shop_center_txt: '商铺中心',
  score_txt: '积分',
  minute_txt: '分',
  wallet_txt: '钱包',
  today_txt: '本日',
  week_txt: '本周',
  month_txt: '本月',
  year_txt: '整年',
  main_indicators_txt: '主要指标',
  sale_price: '销售价格',
  expected_profit: '预期利润',
  order_count: '订单数量',
  goods_click_count: '产品点击数',
  supplier_txt: '供应商',
  friends_help_txt: '朋友助力',
  feedback_txt: '反馈',
  feedback: '意见反馈',
  online_service_txt: '在线服务',
  q_and_a_txt: '问答',
  open_now_txt: '开启',
  certification_activation_code: '认证激活码',
  plz_write_invite_code: '请填写邀请码',
  time_txt: '时',
  times_txt: '时间',

  wallet_total_assets: '总财产',
  balance_txt: '余额',
  pending_settlement_txt: '待结算',
  recharge_txt: '充值',
  withdraw_txt: '提现',
  fund_details_txt: '资金明细',
  no_more_txt: '没有更多了',
  no_data_txt: '暂无数据',
  detail_txt: '明细详情',
  num_txt: '编号',
  money_txt: '金额',
  remark_txt: '备注',
  no_remark_txt: '无备注',
  tip_txt: '提示',
  tip_content_txt: '由于各国商户承销币种问题，目前',
  need_contact_service: '需联系客服',
  contact_service: '联系客服',

  feedback_title: '反馈你的意见',
  feedback_placeholder: '出于安全考虑，请勿包含个人信息',
  submit_txt: '提交',
  sure_txt: '确定',
  submit_suc_txt: '提交成功',

  loading: '加载中...',

  newer_action: '新人活动',
  newer_traffic_package: '新人扶持流量包',
  newer_get_millions_of_products: '领取百万商品',
  newer_get: '收到',
  newer_receive: '领取',
  newer_action_ruler: '新人活动规则',
  newer_receive_suc: '领取成功',

  help_friends_help: '好友助力',
  help_your_friends_stores: '助力你的好友店铺',
  get_store_product_heating: '获取店铺商品加热',
  help_friends_txt: '助力朋友',
  help_friends_activity_rules: '助力好友活动规则',
  assistance_details: '助力明细',
  help_friend_name: '助力好友名',
  plz_input: '请输入',
  plz_input_friend_name: '请输入助力好友名',
  help_suc: '助力成功',

  online_service: '在线客服',
  online_24_hour_service: '24小时在线支持',
  customer_service: '客户服务',
  copy_and_add_id: '复制并添加LINE ID',
  plz_input_question_detail: '请输入你的问题的详细信息',
  harry_reply_your: '我们会尽快回复你',
  click_copy: '点击复制',
  copy_suc: '复制成功',
  copy_fail: '复制失败',

  overall: '整体',
  cooperate: '合作',
  cancel: '取消',
  supplier_num: '供货商编号',
  no_use_supplier: '没有可用的供应商',
  here_add_supplier: '在这里添加新供应商',
  add_supplier: '添加供货商',
  plz_input_supplier_num: '请输入供货商编号',
  add_suc: '添加成功',

  new_products_launched: '上架新商品',
  profit_txt: '利润',
  has_been_added: '已上架',
  on_display: '展示中',
  coming_soon_txt: '上架中',
  sold_txt: '已售出',
  removed_txt: '已下架',

  order_detail_txt: '订单详情',
  waiting_express_delivery: '等待快递揽收',
  customer_info: '客户信息',
  order_good: '订单商品',
  order_num: '订单号',
  order_date: '订单日期',
  order_content: '订单摘要',
  purchase_price_txt: '进价',
  income_txt: '收入',

  order_txt: '订单',
  pending_payment: '待付款',
  ready_for_shipment: '准备发货',
  shipped_txt: '已发货',
  paid_txt: '已交付',
  good_num: '商品编号',
  pay_and_ship: '付款与发货',
  pay_suc: '支付成功',
  no_order: '暂无订单',
  no_order_tips: '你可以在这里追踪你的订单和退货',
  suer_order: '确认付款',
  pay_way: '支付方式',
  wallet_pay: '钱包支付',
  sure_pay: '确认支付',
  paying_txt: '支付中...',

  manage_showcase: '管理橱窗',
  add_new_good: '添加新商品',
  heating_txt: '加热',
  promotion: '推广',
  promotion_tip: '推广提示',
  promotion_tip_txt: '您当前的积分低于200，您无法为你的产品付款',
  copy_link: '复制链接',
  scan_qr_code: '扫描二维码',

  about_good: '关于商品',
  specification: '规格',
  product_overview: '产品概览',

  top_tips_1: '抖音店铺线上转线上',
  top_tips_2: '目前仅支持内部邀请',
  active_place_holeder: '请输入',
}
