export default {
  shop_center_txt: 'Trung tâm mua sắm',
  score_txt: 'Điểm',
  minute_txt: 'phút',
  wallet_txt: 'ví',
  today_txt: 'Hôm nay',
  week_txt: 'tuần này',
  month_txt: 'tháng này',
  year_txt: 'cả năm',
  main_indicators_txt: 'Các chỉ báo chính',
  sale_price: 'giá bán',
  expected_profit: 'lợi nhuận dự kiến',
  order_count: 'Số lượng đặt hàng',
  good_click_count: 'Số lần nhấp chuột vào sản phẩm',
  supplier_txt: 'Nhà cung cấp',
  friend_help_txt: 'Bạn bè giúp đỡ',
  feedback_txt: 'Phản hồi',
  feedback: 'Nhận xét',
  online_service_txt: 'Dịch vụ trực tuyến',
  q_and_a_txt: 'Hỏi đáp',
  open_now_txt: 'Mở',
  certification_activation_code: 'Mã kích hoạt chứng chỉ',
  plz_write_invite_code: 'Vui lòng điền mã mời',
  time_txt: 'thời gian',
  times_txt: 'thời gian',

  wallet_total_assets: 'Tổng tài sản',
  balance_txt: 'Số dư',
  pending_settlement_txt: 'đang chờ giải quyết',
  recharge_txt: 'Nạp tiền',
  withdraw_txt: 'Rút tiền',
  fund_details_txt: 'Chi tiết quỹ',
  no_more_txt: 'Không còn nữa',
  no_data_txt: 'Chưa có dữ liệu',
  detail_txt: 'Chi tiết',
  num_txt: 'Số',
  money_txt: 'số tiền',
  remark_txt: 'Nhận xét',
  no_remark_txt: 'Không có bình luận',
  tip_txt: 'mẹo',
  tip_content_txt:
    'Hiện tại do vấn đề tiền tệ được bảo lãnh bởi người bán ở nhiều quốc gia khác nhau',
  need_contact_service: 'Cần liên hệ với bộ phận dịch vụ khách hàng',
  contact_service: 'Liên hệ bộ phận dịch vụ khách hàng',

  feedback_title: 'Phản hồi ý kiến ​​của bạn',
  feedback_placeholder:
    'Vì lý do bảo mật, vui lòng không đưa thông tin cá nhân',
  submit_txt: 'Gửi',
  sure_txt: 'Được',
  submit_suc_txt: 'Gửi thành công',

  loading: 'Đang tải...',

  new_action: 'Hoạt động của người mới',
  new_traffic_package: 'Gói lưu lượng hỗ trợ người mới',
  new_get_millions_of_products: 'Nhận hàng triệu sản phẩm',
  new_get: 'đã nhận',
  new_receive: 'nhận',
  new_action_ruler: 'Quy tắc hoạt động của người mới',
  new_receive_suc: 'Đã nhận thành công',

  help_friends_help: 'Bạn bè giúp đỡ',
  help_your_friends_stores: 'Trợ giúp cửa hàng của bạn bè',
  get_store_product_heating: 'Nhận sản phẩm sưởi ấm tại cửa hàng',
  help_friends_txt: 'Giúp đỡ bạn bè',
  help_friends_activity_rules: 'Trợ giúp các quy tắc hoạt động của bạn bè',
  assistance_details: 'Chi tiết hỗ trợ',
  help_friend_name: 'Giúp đỡ tên bạn bè',
  plz_input: 'Xin vui lòng nhập',
  plz_input_friend_name:
    'Xin vui lòng nhập tên bạn bè của bạn để được trợ giúp',
  help_suc: 'Giúp thành công',

  online_service: 'Dịch vụ khách hàng trực tuyến',
  online_24_hour_service: 'Hỗ trợ trực tuyến 24 giờ',
  customer_service: 'dịch vụ khách hàng',
  copy_and_add_id: 'Sao chép và thêm ID LINE',
  plz_input_question_detail: 'Vui lòng nhập chi tiết câu hỏi của bạn',
  harry_reply_your: 'Chúng tôi sẽ trả lời bạn sớm nhất có thể',
  click_copy: 'Click để sao chép',
  copy_suc: 'Sao chép thành công',
  copy_fail: 'Sao chép thất bại',

  overall: 'tổng thể',
  cooperate: 'hợp tác',
  cancel: 'hủy',
  supplier_num: 'Mã số nhà cung cấp',
  no_use_supplier: 'Không có nhà cung cấp nào',
  here_add_supplier: 'Thêm nhà cung cấp mới tại đây',
  add_supplier: 'Thêm nhà cung cấp',
  plz_input_supplier_num: 'Xin vui lòng nhập số nhà cung cấp',
  add_suc: 'Thêm thành công',

  new_products_launched: 'Ra mắt sản phẩm mới',
  profit_txt: 'Lợi nhuận',
  has_been_added: 'Đã có trên kệ',
  on_display: 'trên màn hình',
  coming_soon_txt: 'Có sẵn ngay bây giờ',
  sold_txt: 'Đã bán',
  removed_txt: 'Đã xóa',

  order_detail_txt: 'Chi tiết đơn hàng',
  wait_express_delivery: 'Đang chờ chuyển phát nhanh',
  customer_info: 'Thông tin khách hàng',
  order_good: 'Đặt hàng',
  order_num: 'Mã đơn hàng',
  order_date: 'ngày đặt hàng',
  order_content: 'Tóm tắt đơn hàng',
  purchase_price_txt: 'Giá mua',
  income_txt: 'Thu nhập',

  order_txt: 'Đặt hàng',
  pending_payment: 'thanh toán đang chờ xử lý',
  ready_for_shipment: 'Sẵn sàng giao hàng',
  shipped_txt: 'đã vận chuyển',
  pay_txt: 'Đã giao',
  good_num: 'số mục',
  pay_and_ship: 'Thanh toán và Giao hàng',
  pay_suc: 'Thanh toán thành công',
  no_order: 'Chưa có đơn hàng nào',
  no_order_tips: 'Bạn có thể theo dõi đơn hàng và trả lại tại đây',
  suer_order: 'Xác nhận thanh toán',
  pay_way: 'phương thức thanh toán',
  wallet_pay: 'thanh toán bằng ví',
  sure_pay: 'Xác nhận thanh toán',
  paying_txt: 'Đang thanh toán...',

  manage_showcase: 'Quản lý trưng bày',
  add_new_good: 'Thêm sản phẩm mới',
  heating_txt: 'Sưởi ấm',
  promotion: 'khuyến mãi',
  promotion_tip: 'Mẹo khuyến mãi',
  promotion_tip_txt:
    'Điểm hiện tại của bạn dưới 200 và bạn không thể thanh toán cho sản phẩm của mình',
  copy_link: 'Sao chép liên kết',
  scan_qr_code: 'Quét mã QR',

  about_good: 'Giới thiệu về sản phẩm',
  specification: 'đặc điểm kỹ thuật',
  product_overview: 'Tổng quan về sản phẩm',

  top_tips_1: 'Chuyển đổi cửa hàng Douyin trực tuyến',
  top_tips_2: 'Hiện tại chỉ hỗ trợ lời mời nội bộ',
  active_place_holeder: 'Xin vui lòng nhập',
}
