export default {
  shop_center_txt: 'Shop center',
  score_txt: 'Points',
  minute_txt: 'Minute',
  wallet_txt: 'Wallet',
  today_txt: 'Today',
  week_txt: 'Week',
  month_txt: 'Month',
  year_txt: 'Year',
  main_indicators_txt: 'Main indicators',
  sale_price: 'Sale price',
  expected_profit: 'Expected profit',
  order_count: 'Order quantity',
  goods_click_count: 'Number of product clicks',
  supplier_txt: 'Supplier',
  friends_help_txt: 'Friends help',
  feedback_txt: 'Feedback',
  feedback: 'Feedback',
  online_service_txt: 'Online service',
  q_and_a_txt: 'Question and Answer',
  open_now_txt: 'Open',
  certification_activation_code: 'Certification activation code',
  plz_write_invite_code: 'Please fill in the invitation code',
  time_txt: 'Hour',
  times_txt: 'Time',

  wallet_total_assets: 'Total assets',
  balance_txt: 'Balance',
  pending_settlement_txt: 'pending settlement',
  recharge_txt: 'Recharge',
  withdraw_txt: 'Withdraw',
  fund_details_txt: 'Fund details',
  no_more_txt: 'No more',
  no_data_txt: 'No data yet',
  detail_txt: 'Details',
  num_txt: 'Number',
  money_txt: 'Amount',
  remark_txt: 'Remarks',
  no_remark_txt: 'No remark',
  tip_txt: 'Tip',
  tip_content_txt:
    'Due to currency issues underwritten by merchants in various countries, currently',
  need_contact_service: 'Need to contact customer service',
  contact_service: 'Contact customer service',

  feedback_title: 'Feedback your opinion',
  feedback_placeholder:
    'For security reasons, please do not include personal information',
  submit_txt: 'Submit',
  sure_txt: 'OK',
  submit_suc_txt: 'Submission successful',

  loading: 'loading...',

  newer_action: 'Newcomer activity',
  newer_traffic_package: 'Newcomer support traffic package',
  newer_get_millions_of_products: 'Get millions of products',
  newer_get: 'Received',
  newer_receive: 'Receive',
  newer_action_ruler: 'Newcomer activity rules',
  newer_receive_suc: 'Received successfully',

  help_friends_help: 'Friends help',
  help_your_friends_stores: 'Help your friends` stores',
  get_store_product_heating: 'Get store product heating',
  help_friends_txt: 'Help friends',
  help_friends_activity_rules: 'Help friends activity rules',
  assistance_details: 'Assistance details',
  help_friend_name: 'Help friend name',
  plz_input: 'Please enter',
  plz_input_friend_name: 'Please enter the name of your friend to help',
  help_suc: 'Help success',

  online_service: 'Online customer service',
  online_24_hour_service: '24-hour online support',
  customer_service: 'Customer Service',
  copy_and_add_id: 'Copy and add LINE ID',
  plz_input_question_detail: 'Please enter the details of your question',
  harry_reply_your: 'We will reply to you as soon as possible',
  click_copy: 'Click to copy',
  copy_suc: 'Copy successful',
  copy_fail: 'Copy failed',

  overall: 'Overall',
  cooperate: 'Cooperate',
  cancel: 'Cancel',
  supplier_num: 'Supplier number',
  no_use_supplier: 'No available supplier',
  here_add_supplier: 'Add new supplier here',
  add_supplier: 'Add supplier',
  plz_input_supplier_num: 'Please enter the supplier number',
  add_suc: 'Add successfully',

  new_products_launched: 'New products launched',
  profit_txt: 'Profit',
  has_been_added: 'Already on the shelves',
  on_display: 'On display',
  coming_soon_txt: 'Available now',
  sold_txt: 'Sold',
  removed_txt: 'Removed',

  order_detail_txt: 'Order details',
  waiting_express_delivery: 'Waiting for express delivery',
  customer_info: 'Customer information',
  order_good: 'Order Goods',
  order_num: 'Order number',
  order_date: 'order date',
  order_content: 'Order Summary',
  purchase_price_txt: 'Purchase price',
  income_txt: 'Income',

  order_txt: 'Order',
  pending_payment: 'Pending payment',
  ready_for_shipment: 'Ready for shipment',
  shipped_txt: 'Shipped',
  paid_txt: 'Delivered',
  good_num: 'Item number',
  pay_and_ship: 'Payment and Shipment',
  pay_suc: 'Payment successful',
  no_order: 'No order yet',
  no_order_tips: 'You can track your orders and returns here',
  suer_order: 'Confirm payment',
  pay_way: 'payment method',
  wallet_pay: 'Wallet payment',
  sure_pay: 'Confirm payment',
  paying_txt: 'Paying...',

  manage_showcase: 'Manage showcase',
  add_new_good: 'Add new product',
  heating_txt: 'Heating',
  promotion: 'Promotion',
  promotion_tip: 'Promotion tip',
  promotion_tip_txt:
    'Your current points are less than 200 and you cannot pay for your products',
  copy_link: 'Copy link',
  scan_qr_code: 'Scan QR code',

  about_good: 'About the product',
  specification: 'Specification',
  product_overview: 'Product Overview',

  top_tips_1: 'Tiktok Shop Online to Online',
  top_tips_2: 'Currently only internal invitations are supported',
  active_place_holeder: 'Please enter',
}
