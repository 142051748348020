export default {
  shop_center_txt: 'Торговый центр',
  score_txt: 'Очки',
  minute_txt: 'минута',
  wallet_txt: 'кошелек',
  today_txt: 'Сегодня',
  week_txt: 'на этой неделе',
  month_txt: 'в этом месяце',
  year_txt: 'весь год',
  main_indicators_txt: 'Основные индикаторы',
  sale_price: 'цена продажи',
  expected_profit: 'ожидаемая прибыль',
  order_count: 'Количество заказа',
  goods_click_count: 'Количество кликов по товару',
  supplier_txt: 'Поставщик',
  friends_help_txt: 'Друзья помогают',
  feedback_txt: 'Отзыв',
  feedback: 'Обратная связь',
  online_service_txt: 'Онлайн-сервис',
  q_and_a_txt: 'Вопрос и ответ',
  open_now_txt: 'Открыть',
  certificate_activation_code: 'Код активации сертификации',
  plz_write_invite_code: 'Пожалуйста, введите код приглашения',
  time_txt: 'время',
  times_txt: 'время',

  wallet_total_assets: 'Общая сумма активов',
  balance_txt: 'Баланс',
  pending_settlement_txt: 'ожидающее урегулирование',
  charge_txt: 'Пополнение',
  draw_txt: 'Вывести',
  fund_details_txt: 'Детали фонда',
  no_more_txt: 'Больше нет',
  no_data_txt: 'Данных пока нет',
  Detail_txt: 'Подробности',
  num_txt: 'Номер',
  money_txt: 'сумма',
  remark_txt: 'Примечания',
  no_remark_txt: 'Нет замечаний',
  tip_txt: 'совет',
  tip_content_txt:
    'В настоящее время из-за проблем с валютой, подписанных продавцами в разных странах',
  need_contact_service: 'Необходимо обратиться в службу поддержки',
  contact_service: 'Обратиться в службу поддержки клиентов',

  feedback_title: 'Оставьте свое мнение',
  feedback_placeholder: 'В целях безопасности не указывайте личную информацию',
  submit_txt: 'Отправить',
  sure_txt: 'ОК',
  submit_suc_txt: 'Отправка успешна',

  loading: 'Загрузка...',

  newer_action: 'Активность новичков',
  newer_traffic_package: 'Пакет поддержки трафика для новичков',
  newer_get_millions_of_products: 'Получите миллионы товаров',
  newer_get: 'получено',
  newer_receive: 'получить',
  newer_action_ruler: 'Правила активности для новичков',
  newer_receive_suc: 'Успешно получено',

  help_friends_help: 'Друзья помогают',
  help_your_friends_stores: 'Помогайте магазинам своих друзей',
  get_store_product_heating: 'Получить нагрев товаров в магазине',
  help_friends_txt: 'Помочь друзьям',
  help_friends_activity_rules: 'Правила активности помощи друзьям',
  help_details: 'Детали помощи',
  help_friend_name: 'Помочь имя друга',
  plz_input: 'Пожалуйста, введите',
  plz_input_friend_name: 'Пожалуйста, введите имя вашего друга, чтобы помочь',
  help_suc: 'Помогите успешно',

  online_service: 'Онлайн-обслуживание клиентов',
  online_24_hour_service: 'Круглосуточная онлайн-поддержка',
  customer_service: 'обслуживание клиентов',
  copy_and_add_id: 'Скопировать и добавить идентификатор ЛИНИИ',
  plz_input_question_detail: 'Пожалуйста, введите детали вашего вопроса',
  harry_reply_your: 'Мы ответим вам как можно скорее',
  click_copy: 'Нажмите, чтобы скопировать',
  copy_suc: 'Копирование выполнено успешно',
  copy_fail: 'Не удалось скопировать',

  overall: 'в целом',
  cooperate: 'сотрудничать',
  cancel: 'отменить',
  supplier_num: 'Номер поставщика',
  no_use_supplier: 'Нет доступного поставщика',
  here_add_supplier: 'Добавьте сюда нового поставщика',
  add_supplier: 'Добавить поставщика',
  plz_input_supplier_num: 'Пожалуйста, введите номер поставщика',
  add_suc: 'Добавление успешно',

  new_products_launched: 'Выпущены новые продукты',
  profit_txt: 'Прибыль',
  has_been_added: 'Уже на полках',
  on_display: 'на дисплее',
  coming_soon_txt: 'Доступно сейчас',
  sold_txt: 'Продано',
  removed_txt: 'Удалено',

  order_detail_txt: 'Детали заказа',
  wait_express_delivery: 'Ожидание экспресс-доставки',
  customer_info: 'Информация о клиенте',
  order_good: 'Заказать товары',
  order_num: 'Номер заказа',
  order_date: 'дата заказа',
  order_content: 'Сводка заказа',
  purchase_price_txt: 'Цена покупки',
  income_txt: 'Доход',

  order_txt: 'Заказ',
  pending_paying: 'ожидающий платеж',
  ready_for_shipment: 'Готов к отправке',
  shipped_txt: 'отправлено',
  paid_txt: 'Доставлено',
  good_num: 'номер товара',
  pay_and_ship: 'Оплата и отгрузка',
  pay_suc: 'Платеж успешен',
  no_order: 'Пока нет заказа',
  no_order_tips: 'Здесь вы можете отслеживать свои заказы и возвраты',
  suer_order: 'Подтвердить платеж',
  pay_way: 'способ оплаты',
  wallet_pay: 'платеж через кошелек',
  sure_pay: 'Подтвердить платеж',
  paying_txt: 'Платит...',

  manage_showcase: 'Управление витриной',
  add_new_good: 'Добавить новый товар',
  heating_txt: 'Отопление',
  promotion: 'продвижение',
  promotion_tip: 'Совет по продвижению',
  promotion_tip_txt:
    'Ваше текущее количество баллов меньше 200, и вы не можете платить за свои продукты',
  copy_link: 'Копировать ссылку',
  scan_qr_code: 'Сканировать QR-код',

  about_good: 'О товаре',
  specification: 'спецификация',
  product_overview: 'Обзор продукта',

  top_tips_1: 'Преобразуйте онлайн-магазин Douyin',
  top_tips_2: 'В настоящее время поддерживаются только внутренние приглашения',
  active_place_holeder: 'Пожалуйста, введите',
}
