import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import 'vant/lib/index.css'
import 'amfe-flexible'
import MyNavBar from './components/NavBar.vue'
import i18n from './lang'
import {
  Button,
  NavBar,
  Field,
  Tab,
  Tabs,
  Popup,
  Swipe,
  SwipeItem,
  List,
} from 'vant'

const app = createApp(App)
app.use(router)
app.component('NavBar', MyNavBar)
app
  .use(Button)
  .use(NavBar)
  .use(Field)
  .use(Tab)
  .use(Tabs)
  .use(Popup)
  .use(Swipe)
  .use(SwipeItem)
  .use(List)
  .use(i18n)
app.mount('#app')
