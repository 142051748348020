import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/question',
    component: () => import('../views/question.vue'),
  },
  {
    path: '/feedback',
    component: () => import('../views/feedback.vue'),
  },
  {
    path: '/newcomer',
    component: () => import('../views/newcomer.vue'),
  },
  {
    path: '/help',
    component: () => import('../views/help.vue'),
  },
  {
    path: '/service',
    component: () => import('../views/service.vue'),
  },
  {
    path: '/wallet',
    component: () => import('../views/wallet.vue'),
  },
  {
    path: '/supplier',
    component: () => import('../views/supplier.vue'),
  },
  {
    path: '/goods',
    component: () => import('../views/goodsList.vue'),
  },
  {
    path: '/goods/detail',
    component: () => import('../views/goodsDetail.vue'),
  },
  {
    path: '/showcase',
    component: () => import('../views/showcase.vue'),
  },
  {
    path: '/order',
    component: () => import('../views/order.vue'),
  },
  {
    path: '/order/detail',
    component: () => import('../views/orderDetail.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
